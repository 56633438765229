import { SvgIcon, SvgIconProps } from '@mui/material';

export const XmasPlay = (props: SvgIconProps) => (
  <SvgIcon width="28" height="29" fill="none" viewBox="0 0 28 29" {...props}>
    <path
      fill="#808080"
      d="M24.825 5.809h-1.273v-.385c0-1.709-.983-3.179-2.564-3.828-1.58-.658-3.306-.307-4.52.897l-2.34 2.341-2.342-2.34C10.581 1.28 8.846.937 7.275 1.587 5.694 2.245 4.71 3.707 4.71 5.415V5.8H3.173c-1.093 0-1.99.88-1.99 1.965v18.106c0 1.085.897 1.965 1.99 1.965h21.652c1.094 0 1.99-.88 1.99-1.965V7.774c0-1.085-.896-1.965-1.99-1.965Zm-7.143-2.111c.846-.846 1.93-.829 2.657-.53.726.3 1.504 1.051 1.504 2.247V5.8H15.57l2.11-2.11v.008ZM6.42 5.415c0-1.196.778-1.948 1.504-2.247.726-.299 1.803-.324 2.657.53l2.11 2.11h-6.27v-.393ZM25.21 25.88c0 .205-.18.385-.385.385H3.173a.383.383 0 0 1-.384-.385V7.774c0-.205.18-.385.384-.385h21.652c.213 0 .384.171.384.385v18.105Z"
    />
    <path
      fill="#808080"
      d="m19.203 15.233-7.374-4.178c-1.282-.71-2.828.205-2.828 1.632v8.288c0 1.47 1.606 2.29 2.82 1.64l7.373-4.152c1.24-.718 1.24-2.512 0-3.23h.009Zm-.812 1.854-7.348 4.136c-.205.102-.444-.06-.444-.248v-8.288c0-.223.23-.36.435-.248l7.357 4.17a.28.28 0 0 1 0 .478Z"
    />
  </SvgIcon>
);
