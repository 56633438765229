import { SvgIcon, SvgIconProps } from '@mui/material';
import { useId } from 'react';

export const XmasAiActive = (props: SvgIconProps) => {
  const b = useId();
  const c = useId();
  const d = useId();
  const e = useId();
  const f = useId();

  return (
    <SvgIcon width="28" height="29" fill="none" viewBox="0 0 28 29" {...props}>
      <path
        fill={`url(#${b})`}
        d="M17.578 5.908V3.385a.827.827 0 0 0-.824-.824h-2.497V1.374a.827.827 0 0 0-.825-.824.827.827 0 0 0-.824.824v1.187h-2.506a.827.827 0 0 0-.824.824v2.712s.017.066.025.107c0 .033-.025.066-.025.107 0 .454.371.825.824.825h3.841a.91.91 0 0 0 .495.165c5.358 0 9.71 4.343 9.71 9.71a9.706 9.706 0 0 1-9.71 9.71 9.705 9.705 0 0 1-9.71-9.71.918.918 0 0 0-.915-.916.918.918 0 0 0-.915.915c0 6.372 5.169 11.54 11.54 11.54 6.371 0 11.54-5.168 11.54-11.54 0-5.283-3.553-9.726-8.391-11.094l-.009-.008ZM15.93 5.57c-.495-.066-.99-.107-1.5-.107-.033 0-.066.016-.1.016h-3.403v-1.27h5.003v1.36Z"
      />
      <path
        fill={`url(#${c})`}
        d="M7.768 9.318H6.464l.453-.452a.537.537 0 1 0-.76-.76l-.453.453v-1.46a.54.54 0 0 0-.539-.54.54.54 0 0 0-.539.54v1.46l-.452-.453a.537.537 0 1 0-.76.76l.452.452H2.563a.54.54 0 0 0-.539.54.54.54 0 0 0 .54.538h1.303l-.452.453a.537.537 0 0 0 .382.916c.14 0 .275-.054.383-.156l.453-.453v1.46a.54.54 0 0 0 .539.54.54.54 0 0 0 .539-.54v-1.46l.452.453a.53.53 0 0 0 .383.156.537.537 0 0 0 .383-.916l-.453-.453h1.304a.54.54 0 0 0 .539-.539.54.54 0 0 0-.539-.539h-.01Z"
      />
      <path
        fill={`url(#${d})`}
        d="M20.556 19.803c0 .29-.099.527-.298.71a.97.97 0 0 1-.697.274.986.986 0 0 1-.71-.274.937.937 0 0 1-.286-.71c0-.29.096-.526.287-.709a.963.963 0 0 1 .709-.286c.274 0 .506.095.697.286.199.183.299.42.299.71Z"
      />
      <path
        fill={`url(#${e})`}
        d="M16.943 20.625h-1.505v-6.509h1.505v6.509Zm.162-8.413c0 .283-.091.507-.274.672a.93.93 0 0 1-.647.25.946.946 0 0 1-.66-.25c-.182-.165-.273-.39-.273-.672 0-.29.091-.518.274-.684a.946.946 0 0 1 .66-.249.93.93 0 0 1 .646.249c.183.166.274.394.274.684Z"
      />
      <path
        fill={`url(#${f})`}
        d="M11.068 19.567c.464 0 .817-.025 1.057-.075v-1.667a3.364 3.364 0 0 0-.884-.112c-.165 0-.335.012-.51.037-.165.025-.319.07-.46.137a.828.828 0 0 0-.323.286.753.753 0 0 0-.125.448c0 .357.112.606.336.747.224.133.527.199.909.199Zm-.125-5.613c.498 0 .917.063 1.257.187.34.125.61.299.809.523.207.224.352.498.435.821.092.315.137.664.137 1.046v3.944c-.232.05-.585.108-1.057.175a10.04 10.04 0 0 1-1.58.112c-.39 0-.748-.038-1.071-.112a2.313 2.313 0 0 1-.834-.361 1.781 1.781 0 0 1-.535-.647c-.124-.266-.187-.594-.187-.983 0-.374.07-.69.212-.946.15-.258.348-.465.597-.623a2.4 2.4 0 0 1 .859-.348 4.696 4.696 0 0 1 1.033-.112c.166 0 .34.012.522.037.183.017.378.05.585.1v-.249c0-.174-.02-.34-.062-.498a.949.949 0 0 0-.224-.41.94.94 0 0 0-.41-.287 1.725 1.725 0 0 0-.635-.1c-.349 0-.668.026-.958.075-.29.05-.527.108-.71.175l-.186-1.22c.19-.066.468-.133.833-.199.365-.066.755-.1 1.17-.1Z"
      />
      <defs>
        <linearGradient
          id={b}
          x1={2.898}
          x2={25.978}
          y1={14.55}
          y2={14.55}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#31D4B6" />
          <stop offset={1} stopColor="#48FF9B" />
        </linearGradient>
        <linearGradient
          id={c}
          x1={2.023}
          x2={8.318}
          y1={9.857}
          y2={9.857}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#31D4B6" />
          <stop offset={1} stopColor="#48FF9B" />
        </linearGradient>
        <linearGradient
          id={d}
          x1={18.565}
          x2={20.556}
          y1={19.797}
          y2={19.797}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#31D4B6" />
          <stop offset={1} stopColor="#48FF9B" />
        </linearGradient>
        <linearGradient
          id={e}
          x1={15.251}
          x2={17.105}
          y1={15.952}
          y2={15.952}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#31D4B6" />
          <stop offset={1} stopColor="#48FF9B" />
        </linearGradient>
        <linearGradient
          id={f}
          x1={8.317}
          x2={13.581}
          y1={17.358}
          y2={17.358}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#31D4B6" />
          <stop offset={1} stopColor="#48FF9B" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
