import * as T from '@aily/graphql-sdk/schema';
import {
  AiLogoOutlined,
  Search,
  SearchActive,
  Settings,
  SettingsActive,
  XmasAi,
  XmasAiActive,
  XmasHome,
  XmasHomeActive,
  XmasPlay,
  XmasPlayActive,
  XmasStar,
  XmasStarActive,
} from '@aily/saas-core/icons';
import { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

type IconVariant = 'default' | 'active';
type IconType = T.MenuIcon | 'SEARCH_OUTLINED';

type IconComponentMap = Partial<
  Record<IconType, Partial<Record<IconVariant, React.ComponentType<SvgIconProps>>>>
>;

const themeIconMappings: IconComponentMap = {
  [T.MenuIcon.AilyLogoOutlined]: {
    default: XmasAi,
    active: XmasAiActive,
  },
  [T.MenuIcon.HomeOutlined]: {
    default: XmasHome,
    active: XmasHomeActive,
  },
  [T.MenuIcon.PlayCircleOutlined]: {
    default: XmasPlay,
    active: XmasPlayActive,
  },
  SEARCH_OUTLINED: {
    default: Search,
    active: SearchActive,
  },
  [T.MenuIcon.SettingsOutlined]: {
    default: Settings,
    active: SettingsActive,
  },
  [T.MenuIcon.StarOutlined]: {
    default: XmasStar,
    active: XmasStarActive,
  },
};

interface Props extends SvgIconProps {
  menuIcon: IconType;
  active?: boolean;
}

export const MenuIcon: React.FC<Props> = ({ menuIcon, active = false, ...rest }) => {
  const variant: IconVariant = active ? 'active' : 'default';
  const IconComponent = themeIconMappings?.[menuIcon]?.[variant] || AiLogoOutlined; // Fallback to a default icon

  return <IconComponent {...rest} />;
};
