import { SvgIcon, SvgIconProps } from '@mui/material';

export const XmasHome = (props: SvgIconProps) => (
  <SvgIcon width="28" height="29" viewBox="0 0 28 29" {...props}>
    <g
      stroke="#808080"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.532}
      fill="none"
    >
      <path d="M14 24.451H5.28c-.967 0-1.249-.969-.9-1.405.35-.436 5.39-6.148 5.39-6.148H6.525c-.954 0-1.21-.878-.724-1.421.392-.438 5.53-6.213 5.53-6.213H8.673c-.623 0-1.04-.816-.583-1.36.456-.546 5.194-5.875 5.194-5.875s.204-.32.7-.32c.498 0 .732.32.732.32s4.738 5.33 5.194 5.874c.456.545.04 1.361-.583 1.361H16.67s5.137 5.776 5.53 6.213c.487.542.23 1.42-.724 1.42H18.23s5.04 5.713 5.39 6.15c.35.435.067 1.404-.9 1.404H14Z" />
      <path d="M11.3 24.449v2.134s.013.808.855.808h3.38c.842 0 .855-.808.855-.808v-2.134" />
    </g>
  </SvgIcon>
);
