import { SvgIcon, SvgIconProps } from '@mui/material';
import { useId } from 'react';

export const XmasStarActive = (props: SvgIconProps) => {
  const a = useId();

  return (
    <SvgIcon width="28" height="29" fill="none" viewBox="0 0 28 29" {...props}>
      <path
        fill={`url(#${a})`}
        d="m24.29 19.476-1.62-.936 1.366-.674a.875.875 0 0 0-.779-1.566l-2.406 1.19-3.5-2.021a3.132 3.132 0 0 0 0-1.855l3.5-2.022 2.406 1.208a.875.875 0 0 0 .779-1.566l-1.365-.674 1.619-.936a.875.875 0 0 0-.875-1.514l-1.62.927.08-1.487a.877.877 0 0 0-1.75-.114l-.175 2.678-3.5 2.012a3.5 3.5 0 0 0-1.575-.954V7.147l2.23-1.487a.875.875 0 1 0-.97-1.453l-1.26.832V3.175a.875.875 0 0 0-1.75 0v1.864l-1.27-.875a.875.875 0 1 0-.97 1.452l2.24 1.531v4.034a3.5 3.5 0 0 0-1.62.928l-3.5-2.013-.13-2.686a.878.878 0 1 0-1.75.14l.078 1.488L4.585 8.1a.875.875 0 0 0-.875 1.514l1.618.936-1.365.674a.878.878 0 1 0 .78 1.575l2.405-1.19 3.5 2.021a3.132 3.132 0 0 0 0 1.855l-3.5 2.022L4.742 16.3a.874.874 0 1 0-.779 1.566l1.365.674-1.618.936a.876.876 0 1 0 .875 1.514l1.618-.927-.078 1.487a.875.875 0 0 0 .813.928H7a.875.875 0 0 0 .875-.823l.175-2.677 3.5-2.013c.429.456.973.789 1.575.963v4.034l-2.232 1.487a.875.875 0 0 0 .972 1.453l1.268-.875v1.898a.875.875 0 0 0 1.75 0v-1.863l1.27.875a.875.875 0 1 0 .97-1.453l-2.248-1.531v-4.025A3.5 3.5 0 0 0 16.493 17l3.5 2.013.175 2.677a.875.875 0 0 0 .875.823h.062a.875.875 0 0 0 .77-.963l-.097-1.513 1.62.936a.875.875 0 0 0 .874-1.514l.018.017ZM14 16.3a1.75 1.75 0 1 1 0-3.499 1.75 1.75 0 0 1 0 3.5Z"
      />
      <defs>
        <linearGradient
          id={a}
          x1={3.24}
          x2={24.695}
          y1={14.55}
          y2={14.55}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#31D4B6" />
          <stop offset={1} stopColor="#48FF9B" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
};
