import { styled } from '@mui/material';

import snowTop from './xmas-head.png';

const XmasHead = styled('div')(() => ({
  position: 'absolute',
  zIndex: 1300,
  top: 0,
  left: 0,
  width: '100%',
  height: 40,
  background: `url(${snowTop}) repeat-x`,
  backgroundSize: 'contain',
  pointerEvents: 'none',
}));

export default XmasHead;
