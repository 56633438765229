import { EventTrackingConfig, getPathDetails, withEventTracking } from '@aily/analytics-service';
import {
  GetDataViewDocument,
  GetDataViewQueryVariables,
  GetFiltersByIdDocument,
  GetFiltersByIdQuery,
  GetFiltersByIdQueryVariables,
} from '@aily/graphql-sdk/core';
import * as T from '@aily/graphql-sdk/schema';
import { useQuery } from '@aily/saas-graphql-client';
import { AiIcon } from '@aily-labs/ui';
import { TypedDocumentNode } from '@apollo/client';
import type { OperationVariables } from '@apollo/client/core';
import {
  Alert,
  Box,
  BoxProps,
  Stack,
  styled,
  TableCell,
  TableCellProps,
  TableRow,
  Typography,
} from '@mui/material';
import { TableRowProps } from '@mui/material/TableRow/TableRow';
import { find, includes, isEqual, map, sortBy, unionBy } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';

import { FilterSearchParams } from '../../constants';
import { useFiltersContext, useGroupedFilters } from '../../contexts';
import {
  useColumnSelect,
  useColumnToggling,
  useDrillId,
  useFilters,
  useHandleLink,
  useLink,
  useLocationMarkers,
  useNavigateSearch,
  useSearchParams,
  useTimelineSeries,
} from '../../hooks';
import { useEnvironment, useModule } from '../../providers';
import { colors } from '../../theme/default/colors';
import {
  createSelectFilterValue,
  getFilterIdFromFilterInput,
  mapFilterComponentToFilterValue,
  mapFilterValueToFilterInput,
  mergeNonZeroFilterValues,
  reduceFilterInputToFilterValue,
  reduceFilterValueToDependentFilterValue,
  reduceFilterValueToFilterInput,
} from '../../utils';
import { DataViewFooterToolbar } from '../DataViewFooterToolbar';
import { FilterToolbar, useHandleFilterAlign, useHandleFilterDisplayMode } from '../FilterToolbar';
import { LoadingBackdrop } from '../LoadingBackdrop';
import { LoadingSkeleton } from '../LoadingSkeleton';
import { SelectFilter } from '../SelectFilter';
import { Table, TableColumnProps, TableProps } from '../Table';
import {
  TableCellCategory,
  TableCellContent,
  TableCellDrillDown,
  TableCellIconLink,
  TableCellIconsLink,
  TableCellMicroChart,
  TableCellProgressBar,
  TableCellTextLink,
} from '../TableCellContent';
import { TableColumnSelectHeaderCell } from '../TableColumnSelect';
import { TimelineSeriesLegend } from '../TimelineSeriesLegend';
import { LocationMarker, WorldMap } from '../WorldMap';
import { MapContainer, MapGridLayout } from './MapGridLayout';
import { useTableDataViewColumns } from './useTableDataViewColumns';
import { aggregationRowCellStyle, headerRowCellStyle, hoverableRowIconCellStyle } from './utils';

export type TableDataViewAbstractType = {
  columns?: T.TableColumnResult[] | null;
  rows?: T.TableRowResult[] | null;
  filters?: T.FilterValue[] | null;
  lastUpdateDate?: string | null;
  metadata?: T.MetaData[] | null;
  availableUserSettings?: T.UserSetting[] | null;
};

export type TableQueryAbstractType = { __typename?: 'Query'; dataView?: TableDataViewAbstractType };

export type ComponentAbstractType = T.Component & {
  filters?: T.FilterComponent[] | null;
  drillIds?: number[] | null;
};

export interface TableDataViewProps<
  TData extends TableQueryAbstractType = TableQueryAbstractType,
  TVariables extends OperationVariables = OperationVariables,
> extends Pick<TableProps<T.TableRowResult>, 'onCellRender'> {
  component: ComponentAbstractType;
  ContainerProps?: BoxProps;
  TableProps?: Partial<TableProps<T.TableRowResult>>;
  local?: boolean;
  onInfoContentRender?: () => React.ReactElement;
  onUserSettingChangeCommitted?: (userSetting: T.UserSetting) => boolean;
  onFilterChange?: (
    filterId: string,
    filterOption: T.FilterOptionResult | T.TreeFilterOptionResult,
    filterCode?: string,
    path?: string,
  ) => void;
  onDrillDown?: (drillDown?: T.DrillDownResult, path?: string) => void;
  onMicroChartClick?: (microChart: T.MicroChartResult, drillDown?: T.DrillDownResult) => void;
  onIconLinkClick?: (iconLink: T.IconLinkResult) => void;
  getDataView?: (data: TData) => TableDataViewAbstractType;
  query?: TypedDocumentNode<TData, TVariables>;
}

const TableContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'loading',
})<{ loading?: boolean }>(({ loading }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxHeight: '100%',
  ...(loading && { opacity: 0.3 }),
}));

const StyledMapGridLayout = styled(MapGridLayout)(({ theme }) => ({
  marginTop: theme.spacing(-2),
  '& .MuiTableCell-head.MuiTableCell-stickyHeader, & .MuiTableCell-root.MuiTableCell-body ': {
    background: 'transparent',
    boxShadow: 'none',
  },
  '& .MuiToolbar-root': {
    zIndex: 300,
  },
}));

const Container = styled(Box)(() => ({
  position: 'relative',
  minHeight: 0,
}));

const resourceAllocationCodes = ['ResourceAllocation::table', 'fin::resource_allocation::table'];

const TableDataView = <
  TData extends TableQueryAbstractType,
  TVariables extends OperationVariables,
>({
  component,
  onCellRender,
  ContainerProps,
  TableProps,
  local,
  onUserSettingChangeCommitted,
  onFilterChange,
  onDrillDown,
  onMicroChartClick,
  onIconLinkClick,
  onInfoContentRender,
  getDataView,
  query = GetDataViewDocument,
}: TableDataViewProps<TData, TVariables>) => {
  const {
    id: componentId,
    code: componentCode,
    drillIds: defaultDrillIds,
    filters: filterComponents,
  } = component;

  const module = useModule();
  const link = useLink();
  const handleLink = useHandleLink();
  const location = useLocation();
  const searchParams = useSearchParams();
  const drillIds = useDrillId() ?? defaultDrillIds;
  const [localDrillIds, setLocalDrillIds] = useState(defaultDrillIds);
  const filtersContext = useFiltersContext();
  const groupedFiltersContext = useGroupedFilters();
  const [loadCount, setLoadCount] = useState(0);
  const { TENANT } = useEnvironment();
  const isSanofiTenant = TENANT.includes('sanofi');

  const [
    isResourceAllocationTable,
    isSiteEnrollmentTable,
    isRndDriversTable,
    isRndWhatIfEnrolTable,
    isRndGnoMilestonesTable,
  ] = useMemo(
    () => [
      includes(resourceAllocationCodes, componentCode),
      componentCode === 'SiteEnrollment::Table',
      componentCode === 'rnd::drivers::table',
      componentCode === 'rnd::recommendedactionsenrol::table',
      componentCode === 'rnd::gnomilestones::table',
    ],
    [componentCode],
  );

  const groupedFilterComponents = useMemo(
    () =>
      groupedFiltersContext && filterComponents
        ? groupedFiltersContext.syncFilterComponentsWithGroups(filterComponents)
        : filterComponents,
    [filterComponents, groupedFiltersContext],
  );

  const visibleFilterIds = useMemo(
    () => filterComponents?.filter(({ isHidden }) => !isHidden)?.map(({ id }) => id) ?? [],
    [filterComponents],
  );

  const defaultFilterValues = useMemo(() => {
    const groupedFilterValues = map(groupedFilterComponents, mapFilterComponentToFilterValue);

    return mergeNonZeroFilterValues(groupedFilterValues, filtersContext.filterValues);
  }, [filtersContext.filterValues, groupedFilterComponents]);

  const defaultFilterInputs = useMemo(
    () => defaultFilterValues?.reduce(reduceFilterValueToFilterInput, []),
    [defaultFilterValues],
  );

  const getDataViewRef = useRef(getDataView);
  const [toolbarFilters, setToolbarFilters] = useState<T.Filter[]>([]);
  const [filterInputs, setFilterInputs] = useState(defaultFilterInputs);
  const [userSettingsInput, setUserSettingsInput] = useState<T.UserSettingInput[]>([]);

  const navigateSearch = useNavigateSearch();

  const handleCompleted = useCallback(() => {
    setLoadCount((prevState) => prevState + 1);
  }, []);

  const variables = useMemo(() => {
    return {
      input: {
        id: componentId,
        moduleId: module?.id ?? '',
        drillIds: local ? localDrillIds : drillIds,
        filters: filterInputs,
        userSettings: userSettingsInput,
        componentCode,
        previousPriorityId: link?.previousPriorityId,
      },
    } satisfies GetDataViewQueryVariables;
  }, [
    componentId,
    module,
    drillIds,
    filterInputs,
    link,
    local,
    localDrillIds,
    userSettingsInput,
    componentCode,
  ]);

  const { loading, error, data } = useQuery<TData>(query, {
    variables,
    onCompleted: handleCompleted,
  });

  const dataView = useMemo(() => {
    if (!data) return;

    return getDataViewRef.current ? getDataViewRef.current(data) : data.dataView;
  }, [data, getDataViewRef]);

  const { isColumnToggleable, isColumnToggled, isColumnVisible, toggleColumn } = useColumnToggling(
    dataView?.columns,
  );

  const { isColumnSelectable, isColumnSelected, selectColumn } = useColumnSelect(dataView?.columns);

  const getFilters = useCallback(
    ({ filtersById }: GetFiltersByIdQuery) => (filtersById ?? []) as T.Filter[],
    [],
  );

  const {
    filters,
    loading: filtersLoading,
    filterValues,
    setFilterValues,
  } = useFilters<GetFiltersByIdQuery, GetFiltersByIdQueryVariables>({
    query: GetFiltersByIdDocument,
    variables: {
      ids: visibleFilterIds,
      moduleId: module?.id,
      filters: filterInputs,
      drillIds: local ? localDrillIds : drillIds,
      viewCode: componentCode,
    },
    getFilters,
    defaultFilterValues,
    skip: !visibleFilterIds.length,
  });

  useUpdateEffect(() => {
    if (filterComponents) {
      const newFilterValues = reduceFilterValueToDependentFilterValue(
        filtersContext.filterValues,
        filterComponents,
      );

      setFilterValues((prevState) => {
        const newState = unionBy(newFilterValues, prevState, 'id');
        if (!isEqual(sortBy(newState, 'id'), sortBy(prevState, 'id'))) {
          return newState;
        }

        return prevState;
      });

      setFilterInputs((prevState) => {
        const newState = newFilterValues.reduce(reduceFilterValueToFilterInput, []);
        const filters = unionBy(newState, prevState, getFilterIdFromFilterInput);

        if (
          !isEqual(
            sortBy(filters, getFilterIdFromFilterInput),
            sortBy(prevState, getFilterIdFromFilterInput),
          )
        ) {
          return filters;
        }
        return prevState;
      });
    }
  }, [filterComponents, filtersContext.filterValues]);

  const backLink = useMemo<T.Link>(
    () => ({
      absolutePath: location.pathname,
      drillIds: local ? localDrillIds : drillIds,
      filters: filterInputs?.reduce(reduceFilterInputToFilterValue, []),
    }),
    [location.pathname, drillIds, filterInputs, local, localDrillIds, searchParams],
  );

  useEffect(() => {
    // Wait for the data to load before loading new filters, otherwise a non-existent filter option may be selected
    if (!dataView || (!loading && !filtersLoading)) {
      setToolbarFilters(
        filters.filter((filter) => {
          const filterComponent = find(filterComponents, { id: filter.id });

          if (!filterComponent) {
            return false;
          }

          // Skip left-aligned filters from being displayed in the toolbar, they will be displayed in the first column header.
          // This is because filters are moved from place to place in the design, without the app having any filter positioning
          // feature implemented, so it has to be hard-coded on the FE.
          if (!filterComponent.alignment || filterComponent.alignment === T.Alignment.Left) {
            return false;
          }

          return true;
        }),
      );
    }
  }, [dataView, loading, filtersLoading, filters]);

  useEffect(() => {
    setFilterValues((prevState) => unionBy(dataView?.filters, prevState, 'id'));
  }, [dataView?.filters]);

  const handleFilterChange = useCallback(
    (
      filterId: string,
      filterOption: T.FilterOptionResult | T.TreeFilterOptionResult,
      filterCode?: string,
    ) => {
      const filterValue = createSelectFilterValue(filterId, filterOption?.value ?? -1);
      const filterInput = mapFilterValueToFilterInput(filterValue) as T.FilterInput;
      const filterComponent = find(filterComponents, { id: filterId });

      setFilterValues((prevState) => unionBy([filterValue], prevState, 'id'));
      setFilterInputs((prevState) => unionBy([filterInput], prevState, getFilterIdFromFilterInput));

      onFilterChange?.(filterId, filterOption, filterCode, location.pathname);

      const filterSyncGroup = filterComponent?.filterSynchronizationGroup;
      if (filterSyncGroup) {
        groupedFiltersContext?.updateGroupValue(filterValue.value, filterSyncGroup);
      }

      if (filterCode && !local) {
        filtersContext?.updateFilterValues([filterValue]);
      }
    },
    [filterComponents, componentCode, groupedFiltersContext, filtersContext, onFilterChange],
  );

  const handleFilterAlign = useHandleFilterAlign(filterComponents);
  const handleFilterDisplayMode = useHandleFilterDisplayMode(filterComponents);

  const columns = useTableDataViewColumns({
    dataView,
    isColumnVisible,
    isColumnToggleable,
    isColumnToggled,
    isColumnSelectable,
    isColumnSelected,
  });

  const getChildrenRows = useCallback((row: T.TableRowResult) => row.children ?? [], []);

  const { locationMarkers, hasLocationMarkers } = useLocationMarkers(dataView);
  const { timelineSeries, hasTimelineSeries } = useTimelineSeries(dataView);

  const handleRow = useCallback(
    (row: T.TableRowResult): TableRowProps => ({
      sx: (theme) => ({
        ...(row.rowType === T.RowType.Header && { background: theme.palette.divider }),
        '& .MuiTableCell-root': {
          ...(row.rowType === T.RowType.Aggregation && aggregationRowCellStyle(theme)),
          ...(row.rowType === T.RowType.Header && headerRowCellStyle(theme)),
        },
        '&:hover .MuiTableCell-root *': {
          ...(row.cells?.some((cell) => T.isIconLinkResult(cell.cellContent)) &&
            hoverableRowIconCellStyle),
        },
      }),
    }),
    [],
  );

  const handleTextLinkClick = useCallback(
    (link?: T.Link | null) => {
      if (link) {
        handleLink(link, backLink);
      }
    },
    [handleLink, backLink],
  );

  const handleMicroChartClick = useCallback(
    (microChartResult: T.MicroChartResult, drillDownResult?: T.DrillDownResult) => {
      if (microChartResult.linkResult) {
        handleLink(microChartResult.linkResult, backLink);
      }

      onMicroChartClick?.(microChartResult, drillDownResult);
    },
    [handleLink, backLink, onMicroChartClick],
  );

  const handleIconsLinkClick = useCallback(
    (iconsLink: T.IconsLinkResult) => {
      if (iconsLink.linkResult) {
        handleLink(iconsLink.linkResult, backLink);
      }
    },
    [handleLink, backLink],
  );

  const handleDrillDownClick = useCallback(
    (drillDown: T.DrillDownResult) => {
      const drillIds = (drillDown.drillDownIds ?? drillDown.drillUpIds ?? []) as number[];
      const dimensionFilterIds = drillDown?.dimensionIds;

      if (local) {
        setLocalDrillIds(drillIds);
        return;
      }

      const newSearchParams = new URLSearchParams(searchParams);

      if (drillIds.length > 0) {
        newSearchParams.set(FilterSearchParams.DRILL_ID, drillIds.join('.'));
      } else {
        newSearchParams.delete(FilterSearchParams.DRILL_ID);
      }

      if (dimensionFilterIds && dimensionFilterIds.length > 0) {
        newSearchParams.set(
          FilterSearchParams.DIMENSION_ID,
          dimensionFilterIds.map(String).join('.'),
        );
      } else {
        newSearchParams.delete(FilterSearchParams.DIMENSION_ID);
      }

      navigateSearch(location.pathname, newSearchParams);
      onDrillDown?.(drillDown, location.pathname);
    },
    [local, searchParams, navigateSearch, location.pathname, onDrillDown],
  );

  const handleIconLinkClick = useCallback(
    onIconLinkClick ??
      ((iconLink: T.IconLinkResult) => {
        if (iconLink.linkResult) {
          handleLink(iconLink.linkResult, backLink);
        }
      }),
    [handleLink, backLink, onIconLinkClick],
  );

  const handleCellRender = useCallback(
    (row: T.TableRowResult, column: TableColumnProps, columnIndex: number) => {
      if (onCellRender) {
        const element = onCellRender(row, column, columnIndex);
        if (element) {
          return element;
        }
      }

      const cellContent = find(row.cells, { columnDataKey: column.dataKey })?.cellContent;
      if (!cellContent) {
        return null;
      }

      const rowName =
        (
          row.cells?.find(
            (cell) => T.isDrillDownResult(cell.cellContent) || T.isTextLinkResult(cell.cellContent),
          )?.cellContent as T.DrillDownResult | T.TextLinkResult | undefined
        )?.value ?? undefined;

      const drillDown = row.cells?.find((cell) => T.isDrillDownResult(cell.cellContent))
        ?.cellContent as T.DrillDownResult | undefined;

      if (T.isTextResult(cellContent) && row.rowType === T.RowType.Category) {
        return <TableCellCategory text={cellContent} />;
      }

      if (columnIndex === 0 && T.isTextResult(cellContent) && !cellContent.subLabels?.length) {
        return (
          <Typography variant="inherit" component="span" data-testid="TextCell">
            {cellContent.value}
          </Typography>
        );
      }

      if (T.isTextLinkResult(cellContent)) {
        return <TableCellTextLink textLink={cellContent} onClick={handleTextLinkClick} />;
      }

      if (T.isMicroChartResult(cellContent)) {
        return (
          <TableCellMicroChart
            microChart={cellContent}
            drillDown={drillDown}
            onClick={handleMicroChartClick}
          />
        );
      }

      if (T.isDrillDownResult(cellContent)) {
        return <TableCellDrillDown drillDown={cellContent} onClick={handleDrillDownClick} />;
      }

      if (T.isIconsLinkResult(cellContent)) {
        return <TableCellIconsLink iconsLink={cellContent} onClick={handleIconsLinkClick} />;
      }

      if (T.isProgressBarResult(cellContent)) {
        return <TableCellProgressBar progressBar={cellContent} />;
      }

      if (T.isIconLinkResult(cellContent)) {
        return <TableCellIconLink iconLink={cellContent} onClick={handleIconLinkClick} />;
      }

      return <TableCellContent key={column.dataKey} value={cellContent} rowName={rowName} />;
    },
    [onCellRender, handleTextLinkClick, handleMicroChartClick, handleDrillDownClick],
  );

  const handleHeaderCellRender = useCallback(
    ({ dataKey }: TableColumnProps, columnIndex: number) => {
      const column = find(dataView?.columns, { dataKey });

      if (columnIndex === 0) {
        const leftAlignedFilters =
          filterComponents
            ?.filter(({ alignment }) => !alignment || alignment === T.Alignment.Left)
            ?.map(({ id }) => filters.find((filter) => filter.id === id))
            ?.filter(T.isSelectFilter) ?? [];

        if (leftAlignedFilters.length) {
          return (
            <Stack direction="row" spacing={1}>
              {leftAlignedFilters.map((filter) => {
                const selectedFilterValue = filterValues.find(
                  (filterValue) =>
                    T.isSelectFilterValue(filterValue) && filterValue.id === filter.id,
                ) as T.SelectFilterValue | undefined;

                return (
                  <SelectFilter
                    key={filter.id}
                    value={selectedFilterValue?.value}
                    filterOptions={filter.options ?? []}
                    onChange={(filterOption) =>
                      handleFilterChange(filter.id, filterOption, filter.filterCode ?? undefined)
                    }
                    filterCode={filter.filterCode ?? undefined}
                  />
                );
              })}
            </Stack>
          );
        }
      }

      if (!!column?.options?.selectGroupId && isColumnSelectable(column)) {
        const { selectGroupId } = column.options;
        return (
          <TableColumnSelectHeaderCell
            columns={dataView?.columns ?? []}
            selectGroupId={selectGroupId}
            isColumnSelected={isColumnSelected}
            isColumnToggled={isColumnToggled}
            selectColumn={selectColumn}
          />
        );
      }

      if (column?.options?.isAiDriven) {
        return (
          <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
            {!isSanofiTenant && (
              <Box>
                <AiIcon width={16} height={16} />
              </Box>
            )}
            <Typography variant="body" fontWeight="bold">
              {column.title}
            </Typography>
            {isSanofiTenant && (
              <Box>
                <AiIcon width={16} height={16} />
              </Box>
            )}
          </Stack>
        );
      }

      return undefined;
    },
    [
      dataView,
      isColumnSelectable,
      isColumnSelected,
      isColumnToggled,
      filterValues,
      isResourceAllocationTable,
      filters,
    ],
  );

  const handleHeaderCell = useCallback(
    (column: TableColumnProps, columnIndex: number) => {
      const style: React.CSSProperties = {};
      const isVarOrAbsColumn = column.dataKey.endsWith('_var') || column.dataKey.endsWith('_abs');

      if (columnIndex === 0) {
        // TODO: This should be configured in the CMS
        style.width = '20%';
      }

      // TODO: This should be configured in the CMS
      if (columnIndex === 0 && isRndWhatIfEnrolTable) {
        style.width = '55%';
      }

      // TODO: Temporary work-around
      if (column.dataKey.toLowerCase() === 'timeline') {
        style.width = '80%';
      }

      if (column.dataKey.toLowerCase() === 'microchart') {
        style.width = '102px';
      }

      if (isResourceAllocationTable && isVarOrAbsColumn) {
        style.width = '90px';
      }

      if (column.dataKey === 'sales_vs_base_tornado') {
        style.width = '20%';
      }

      return { style };
    },
    [hasLocationMarkers, isResourceAllocationTable, isRndWhatIfEnrolTable, isSiteEnrollmentTable],
  );

  const handleCell = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (row: T.TableRowResult, { dataKey }: TableColumnProps, columnIndex: number) => {
      const column = find(dataView?.columns, { dataKey });
      const props: TableCellProps = {};

      if (column && isColumnToggleable(column)) {
        props.sx = { cursor: 'pointer' };
        props.onClick = () => {
          const toggledColumn = toggleColumn(column);
          if (isColumnSelectable(column) && toggledColumn) {
            selectColumn(toggledColumn);
          }
        };
      }

      return props;
    },
    [dataView?.columns, isColumnToggleable, toggleColumn, isColumnSelectable, selectColumn],
  );

  const handleUserSettingChange = useCallback(() => {}, []);

  const handleUserSettingChangeCommitted = useCallback(
    (userSetting: T.UserSetting) => {
      if (onUserSettingChangeCommitted && !onUserSettingChangeCommitted(userSetting)) {
        return;
      }

      const { key, userValue } = userSetting;
      const userSettingInput: T.UserSettingInput = { key, value: userValue as number };
      setUserSettingsInput((prevState) => unionBy([userSettingInput], prevState, 'key'));
    },
    [onUserSettingChangeCommitted],
  );

  const rowKey = useCallback(({ rowId }: T.TableRowResult) => `${rowId}-${loadCount}`, [loadCount]);

  const rows = useMemo(
    () => ((dataView?.rows ?? []) as T.TableRowResult[]).filter(({ cells }) => !!cells),
    [dataView],
  );

  const withoutHeader = useMemo(
    () => dataView?.columns?.every(({ title }) => !title) || isRndDriversTable,
    [dataView, isRndDriversTable],
  );

  const TableFooterProps = useMemo(
    () =>
      hasTimelineSeries
        ? {
            children: (
              <TableRow>
                <TableCell colSpan={columns.length}>
                  <TimelineSeriesLegend series={timelineSeries} />
                </TableCell>
              </TableRow>
            ),
          }
        : undefined,
    [hasTimelineSeries, timelineSeries, columns],
  );

  const TableContainerProps = useMemo(
    () =>
      isResourceAllocationTable
        ? { sx: { position: 'relative', zIndex: 1, height: 'calc(100vh - 480px)' } }
        : undefined,
    [isResourceAllocationTable],
  );

  if (loading && !dataView) {
    return <LoadingSkeleton />;
  }

  const ContainerComponent = hasLocationMarkers ? StyledMapGridLayout : Container;

  return (
    <ContainerComponent {...ContainerProps}>
      {hasLocationMarkers && (
        <MapContainer>
          <WorldMap width="100%" height="100%">
            {locationMarkers.map((marker, index) => (
              <LocationMarker
                key={index}
                lat={marker.position?.coordinates?.latitude || 0}
                lng={marker.position?.coordinates?.longitude || 0}
                label={marker.text || ''}
                color={colors.neutrals.white}
              />
            ))}
          </WorldMap>
        </MapContainer>
      )}
      <TableContainer
        loading={loading}
        sx={{
          '& .MuiTableContainer-root': { ...(isRndGnoMilestonesTable && { overflowX: 'visible' }) },
        }}
      >
        {!!error && (
          <Alert variant="outlined" severity="error">
            {error.graphQLErrors[0]?.message ?? error.message}
          </Alert>
        )}
        {!!toolbarFilters.length && (
          <FilterToolbar
            filters={toolbarFilters}
            selectedFilterOptions={filterValues}
            onFilterChange={handleFilterChange}
            onFilterAlign={handleFilterAlign}
            onFilterDisplayMode={handleFilterDisplayMode}
            sx={{ height: 48 }}
          />
        )}
        <Table
          columns={columns}
          rowKey={rowKey}
          rows={rows}
          getChildrenRows={getChildrenRows}
          onRow={handleRow}
          onHeaderCell={handleHeaderCell}
          onHeaderCellRender={handleHeaderCellRender}
          onCellRender={handleCellRender}
          stickyHeader
          withoutHeader={withoutHeader}
          onCell={handleCell}
          style={{ tableLayout: hasLocationMarkers ? 'auto' : 'fixed' }}
          TableFooterProps={TableFooterProps}
          TableContainerProps={TableContainerProps}
          {...TableProps}
        />
        {!loading && !error && !rows.length && (
          <Alert variant="outlined" severity="info" sx={{ mt: 2 }}>
            No data
          </Alert>
        )}
        {!!dataView && (
          <DataViewFooterToolbar
            dataView={dataView}
            onUserSettingChange={handleUserSettingChange}
            onUserSettingChangeCommitted={handleUserSettingChangeCommitted}
            onInfoContentRender={onInfoContentRender}
          />
        )}
      </TableContainer>
      {loading && <LoadingBackdrop sx={{ backgroundColor: 'transparent' }} />}
    </ContainerComponent>
  );
};

const trackingConfig: EventTrackingConfig<TableDataViewProps> = {
  onDrillDown: {
    eventName: 'priority.clicked',
    getEventProps: (drillDown, path) => {
      const drillIds = drillDown?.drillDownIds ?? drillDown?.drillUpIds;
      const intent = drillDown?.drillDownIds ? 'drill_down' : 'drill_up';

      const { focusArea, focusAreaPath } = getPathDetails(path ?? '');

      return {
        component: 'priority',
        name: drillDown?.value ?? '',
        component_id: drillIds?.join('.') ?? '',
        intent,
        item_type: 'link',
        event_version: '2.0.0',
        focus_area: focusArea,
        focus_area_path: focusAreaPath,
      };
    },
  },
  onMicroChartClick: {
    eventName: 'priority.clicked',
    getEventProps: (microChartResult, drillDownResult) => {
      const { focusArea, focusAreaPath } = getPathDetails(
        microChartResult.linkResult?.absolutePath ?? '',
      );
      return {
        name: drillDownResult?.value ?? '',
        component: 'priority',
        component_id: microChartResult.linkResult?.drillIds?.join('.') ?? '',
        intent: 'click',
        item_type: 'microchart',
        event_version: '2.0.0',
        focus_area: focusArea,
        focus_area_path: focusAreaPath,
      };
    },
  },
  onFilterChange: {
    eventName: 'priorities.filtered',
    getEventProps: (filterId, filterOption, filterCode, path) => {
      const { focusArea, focusAreaPath } = getPathDetails(path ?? '');
      return {
        name: filterOption.label ?? '',
        component: 'priority',
        intent: 'filter',
        item_type: 'table',
        event_version: '2.0.0',
        component_id: filterId,
        focus_area: focusArea,
        focus_area_path: focusAreaPath,
        component_type: filterCode,
      };
    },
  },
};

const TrackedTableDataView = withEventTracking(TableDataView, trackingConfig);

export { TrackedTableDataView as TableDataView };
